import React, { useEffect } from "react"
import { Layout } from "../components/Layout"
import Intro from "../pages/Intro"
import ContactPage from "../pages/Contact"
import SectionBlock from "../components/SectionBlock"
import { Helmet } from "react-helmet"

const IndexPage = () => {
  // animation for main UI content. Makes content appear as it comes into view
  const animateAppear = () => {
    // variables to keep track of elements and window size
    // initiated outside of functions to be reused
    let elements
    let windowHeight

    // selects all the elements in the main section and gets window height
    const init = () => {
      let main = document.querySelector("html")
      elements = main.querySelectorAll("section")
      windowHeight = window.innerHeight
    }
    // checks the position of elements
    const checkPosition = () => {
      const windowStart = 0
      // iterate over elements to alter class names
      for (let element of elements) {
        let positionFromTop = element.getBoundingClientRect().top
        // if the section is in view then animate it
        if (positionFromTop - windowHeight < windowStart) {
          element.classList.add("fade-in-element")
          element.classList.remove("hidden-animate")
        }
        // if the section is out of view then remove animation
        else {
          if (positionFromTop + windowHeight >= windowStart) {
            element.classList.add("hidden-animate")
            element.classList.remove("fade-in-element")
          }
        }
      }
    }
    // when the window resizes, the positions change so the element position
    // has to be calculated again
    window.addEventListener("resize", init)
    // check when use scrolls to add and remove classes
    window.addEventListener("scroll", checkPosition)

    init()
    checkPosition()
  }
  useEffect(() => {
    animateAppear()
  })

  return (
    <Layout>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Jerry Davidson web portfolio projects"
        />
        <title>Jerry Davidson Web Portfolio</title>
      </Helmet>
      <Intro />
    </Layout>
  )
}
export default IndexPage
