import React from "react"
import { SectionLayout } from "../components/Layout"
import { graphql, useStaticQuery } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { library } from "@fortawesome/fontawesome-svg-core"
library.add(fab)

const ContactPage = () => {
  const data = useStaticQuery(graphql`
    query ContactQuery {
      markdownRemark(id: { eq: "84437cec-aa00-5a32-ad1d-c649b7b8a176" }) {
        frontmatter {
          contact {
            icon
            link
            title
          }
          email
        }
      }
    }
  `)
  const contactInfo = data.markdownRemark
  const icons = contactInfo.frontmatter.contact.map((info, i) => (
    <a key={i} href={`${info.link}`} rel="noreferrer noopener" target="_blank">
      <FontAwesomeIcon
        className="contact-icon"
        icon={["fab", `${info.icon}`]}
      />
    </a>
  ))
  return (
    <div id="Contact" className="contact-section">
      <SectionLayout>
        <div className="contact-container">
          <h1>Connect with me!</h1>
          <p className="connect-email">Jerry.Will.Davidson@gmail.com</p>
          <div>{icons}</div>
        </div>
      </SectionLayout>
    </div>
  )
}

export default ContactPage
